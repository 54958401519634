











































































































































import Vue from 'vue'
import { Prop, Component, Watch } from 'vue-property-decorator'
import VuePerfectScrollbar from 'vue-perfect-scrollbar'

@Component({
  components: {
    VuePerfectScrollbar
  }
})
export default class CreatePicklPopup extends Vue {
  public brandId: any = this.$store.state.userData.brandId
  public stores: any = []
  public storeLimitPerPage: number = 1500
  public storeOffset: number = 0
  public tasks: any = []
  public selectedRetails: any = []
  public locationCatalogue: any = { name: '', description: '', brandId: this.brandId }
  public pickl: any = {
    product_id: '',
    survey_id: '',
    start_date_time: '',
    task_ids: [],
    location_ids: [],
    immediate_start: false,
    description: ''
  }
  public selectedStores: any[] = []
  public selectAll: boolean = false
  public showLimitError: boolean = false
  public storeName: string = ''
  public storeCity: string = ''
  public storeZipCode: string = ''
  public storeState: string = ''
  public submittingForm = false
  public val: any = ''
  public date: Date = new Date()
  public time: Date = new Date()
  public settings: any = {}
  public loadingCredits: boolean = true
  public busy: boolean = false
  public lowSrcQuery: string = '?w=300&q=100&fit=crop'
  public states: [] = []
  public stateId: string = ''
  public countryId: number = 234
  public retailers: [] = []
  public storeId: string = ''
  public countries: [] = []
  public duplicatePicklErrorMessage: string = ''

  public limit = 1500
  public offset = 0
  public actionSurvey: any = null
  public surveys: any[] = []
  public totalCount = 0
  public CatalogueSelectedStoreLocations: any[] =[]

  get checkIfStateRetailerSelected () {
    if (this.stateId !== '' && this.storeId !== '') {
      return true
    } else {
      return false
    }
  }

  deleteCatalogueStoreLocation (catalogueStoreLocation: any) {
    this.$store.dispatch('deleteCatalogueStoreLocation', { brandId: this.brandId, locationCatalogueId: this.$route.params.locationCatalogueId, catalogueStoreLocationId: catalogueStoreLocation.id }).then((response: any) => {
      if (response.status === 204) {
        this.$notify({ type: 'success', text: 'Store Location Has Been Deleted Successfully!' })
        this.CatalogueSelectedStoreLocations = []
        this.getCatalogueStoreLocations()
      }
    })
  }
  getCatalogueStoreLocations () {
    this.$store.dispatch('getCatalogueStoreLocations', { brandId: this.brandId, locationCatalogueId: this.$route.params.locationCatalogueId }).then((response: any) => {
      console.log(response.response.body)
      this.CatalogueSelectedStoreLocations = response.response.body.locations
    })
  }

  getLocationCatalogue () {
    this.$store.dispatch('getLocationCatalogueById', { brandId: this.brandId, locationCatalogueId: this.$route.params.locationCatalogueId }).then((response: any) => {
      this.locationCatalogue = response.response.body
      this.getCatalogueStoreLocations()
    })
  }

  setSelected (store: any, selected: boolean) {
    let isObjectPresent = this.selectedRetails.find((o:any) => o.id === store.id)
    if (typeof isObjectPresent !== 'undefined') {
      this.$notify({ type: 'error', text: 'Retailer Already selected' })
      return false
    }
    this.selectedRetails.push({ id: store.id, name: store.store.name, address: store.address })
  }

  inSelectedStoresList (store: any) {
    let isObjectPresent = this.selectedRetails.find((o:any) => o.id === store.id)
    if (typeof isObjectPresent !== 'undefined') {
      return true
    } else {
      return false
    }
  }

  removeRetailer (index: number) {
    this.selectedRetails.splice(index, 1)
  }

  onSelectAll (event: any) {
    if (this.selectAll) {
      this.updatedStores.forEach((store: any) => {
        let isObjectPresent = this.selectedRetails.find((o:any) => o.id === store.id)
        if (typeof isObjectPresent !== 'undefined') {
          return false
        }
        this.selectedRetails.push({ id: store.id, name: store.store.name, address: store.address })
      })
    } else {
      this.updatedStores.forEach((store: any) => {
        let selectedRetailerIndex = this.selectedRetails.findIndex((o:any) => o.id === store.id)
        if (selectedRetailerIndex !== -1) {
          this.removeRetailer(selectedRetailerIndex)
        }
      })
    }
  }

  ValidateAndCreatPickl () {
    this.pickl.location_ids = []
    this.selectedRetails.forEach((x: any) => {
      this.pickl.location_ids.push(x.id)
    })
    this.updateLocationCatalogue()
  }

  saveCatalogueLocationIds (locationCatalogueId: number) {
    let selectedStoreLocations:any = []
    this.selectedRetails.forEach((brand: any) => {
      selectedStoreLocations.push({ id: brand.id })
    })
    let payload = {
      locationCatalogueId: locationCatalogueId,
      storeLocations: selectedStoreLocations,
      brandId: this.$store.state.userData.brandId
    }
    this.$store.dispatch('bulkAssignLocationCatalogueStoreLocations', payload).then((response) => {
      this.$notify({ type: 'success', text: 'Store Cluster has been saved successfully!' })
      this.$router.push('/brand/location-catalogues')
    }, (response) => {
      this.busy = false
    })
  }

  updateLocationCatalogue () {
    this.submittingForm = true
    console.log(this.locationCatalogue)
    this.$store.dispatch('upadtedLocationCatalogue', this.locationCatalogue).then((response) => {
      if (this.selectedRetails.length > 0) {
        this.saveCatalogueLocationIds(this.locationCatalogue.id)
      } else {
        this.$notify({ type: 'success', text: 'Store Cluster has been saved successfully!' })
        this.$router.push('/brand/location-catalogues')
      }
    }, (response) => {
      for (var key in response.body) {
        if (response.status === 400) {
          // this.$notify({ type: 'error', text: response.body[key][0] })
          this.duplicatePicklErrorMessage = response.body.message
        } else {
          this.$notify({ type: 'error', text: response.body[key][0] })
        }
        let field = this.$validator.fields.find({ name: key, scope: 'select-product-form' })
        if (field) {
          this.$validator.errors.add({
            id: field.id,
            field: key,
            msg: response.body[key][0],
            scope: 'select-product-form'
          })
        }
      }
      this.submittingForm = false
      this.$store.commit('hideMessage')
    })
  }
  get updatedStores () {
    let filteredStores = this.stores
    if (this.storeName !== '') {
      filteredStores = filteredStores.filter((m: any) => (m.store.name.toLowerCase().search(this.storeName.trim().toLowerCase()) > -1))
    }
    if (this.storeCity !== '') {
      filteredStores = filteredStores.filter((m: any) => (m.state && m.city.toLowerCase().search(this.storeCity.trim().toLowerCase()) > -1))
    }
    if (this.storeState !== '') {
      filteredStores = filteredStores.filter((m: any) => (m.state && m.state.toLowerCase().search(this.storeState.trim().toLowerCase()) > -1))
    }
    if (this.storeZipCode !== '') {
      filteredStores = filteredStores.filter((m: any) => (m.zip_code && m.zip_code.toLowerCase().search(this.storeZipCode.trim().toLowerCase()) > -1))
    }
    for (var i = 0; i < filteredStores.length; ++i) {
      if (this.selectedStores.indexOf(filteredStores[i]) === -1) {
        this.selectAll = false
        break
      } else if (filteredStores.length - 1 === i) this.selectAll = true
    }
    return filteredStores
  }

  getStoreLocations (clear: boolean = true): void {
    this.$store.dispatch('getStoreLocations', { limit: this.storeLimitPerPage, offset: this.storeOffset, sort: 'name-ASC', storeId: this.storeId, stateId: this.stateId, city: this.storeCity, zipCode: this.storeZipCode, catalogueId: this.$route.params.locationCatalogueId }).then((response: any) => {
      this.stores.push(...response.response.body.data)
      if (this.stores.length < response.response.body.count) {
        this.storeOffset += this.storeLimitPerPage
        this.getStoreLocations()
      }
    })
  }

  getCountries () {
    this.$http.get('countries?service_enabled=true').then((response: any) => {
      this.countries = response.body.data
    })
  }

  getStates () {
    this.stateId = ''
    this.stores = []
    this.selectedStores = []
    this.retailers = []
    this.states = []
    this.storeZipCode = ''
    this.storeCity = ''
    this.$store.commit('showLoading', 'Loading States')
    this.$http.get('states?country_id=' + this.countryId).then((response: any) => {
      this.states = response.body.data
      this.$store.commit('hideMessage')
    })
  }

  getStoresList () {
    this.$store.commit('showLoading', 'Loading Retailers')
    this.storeId = ''
    this.stores = []
    this.selectedStores = []
    this.retailers = []
    this.storeZipCode = ''
    this.storeCity = ''
    if (this.stateId !== '') {
      this.$http.get('stores/get-by-state/' + this.stateId).then((response: any) => {
        this.retailers = response.body.data
        this.$store.commit('hideMessage')
      })
    }
  }

  updateStoreHandler () {
    this.stores = []
    this.storeOffset = 0
    this.selectedStores = []
    this.storeZipCode = ''
    this.storeCity = ''
    if (this.storeId !== '') {
      this.getStoreLocations()
    }
  }

  created () {
    this.brandId = this.$store.state.userData.brandId
    this.loadingCredits = true
    this.time.setHours(this.time.getHours() + 1)
    this.$store.dispatch('getBrandCurrentSubscription', this.brandId).then(() => { this.loadingCredits = false })
    this.$store.dispatch('getAppSettingsKeyValuePair')
    this.getCountries()
    this.getStates()
    this.getLocationCatalogue()
  }
}
